/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M124.9 109.8L94.3 79.2l-1.2-1a50.4 50.4 0 0 0 8.2-27.5 50.6 50.6 0 1 0-23 42.4c.2.4.5.8.9 1.1l30.6 30.7a10.6 10.6 0 0 0 7.5 3.1 10.7 10.7 0 0 0 7.6-18.2M50.7 85.3a34.7 34.7 0 1 1 0-69.4 34.7 34.7 0 0 1 0 69.4"/>'
  }
})
