import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import { login } from '@/api/user'
import store from '@/store'
import axios from 'axios'
import { Message } from 'element-ui'

import { sleep } from '@/utils/index'

export interface IUserState {
  token: string,
  tokenValidTime: number,
  tokenLastUpdateTime: number
}

@Module({ dynamic: true, store: store, name: 'user', preserveState: localStorage.getItem('vuex') !== null })
class User extends VuexModule implements IUserState {
  public token = ''
  public tokenValidTime = 0 // token 有效时长
  public tokenLastUpdateTime = 0 // token 上次更新时间
  public username = '' // 用户名
  public pwd = '' // 密码

  @Mutation
  SET_TOKEN(tokenAbt) {
    this.token = tokenAbt.token
    this.tokenValidTime = tokenAbt.tokenValidTime
    this.tokenLastUpdateTime = +new Date()
  }

  @Mutation
  SET_USER(user) {
    this.username = user.username
    this.pwd = user.pwd
  }

  @Mutation
  public RESET_TOKEN() {
    this.token = ''
    this.tokenValidTime = 0
    this.tokenLastUpdateTime = 0
    this.username = ''
    this.pwd = ''
  }

  @Action
  public async Login(userInfo: { username: string, password: string}) {
    let { username, password } = userInfo
    username = username.trim()

    return axios({
      url: `${process.env.VUE_APP_BASE_API}loginDP`,
      method: 'post',
      data: { username, password, code: '4', uuid: '1111' }
    }).then((data) => {
      return data
    })

    // try {
    //   const { data } = await login({ username, password, code: '4', uuid: '1111' })
    //   this.SET_TOKEN({
    //     token: data.token,
    //     tokenValidTime: data.tokenValidTime
    //   })

    //   // await sleep(1500)
    //   // const rank = Math.random()
    //   // console.log(rank)

    //   // if (rank > 0.5) {
    //   //   this.SET_TOKEN({
    //   //     token: 'sdfsdfsdfsdfsdfsd',
    //   //     tokenValidTime: '600'
    //   //   })
    //   // } else {
    //   //   return Promise.reject(new Error('登录接口调用失败！'))
    //   // }
    // } catch {
    //   this.RESET_TOKEN()
    //   Message({
    //     message: '登录失败！',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    //   throw new Error('登录接口调用失败！')
    // }
  }

  @Action
  public async RefreshToken(userInfo: { username: string, password: string}) {
    let { username, password } = userInfo
    username = username.trim()

    try {
      // 这里要用 axios 发送请求，不然会进拦截器
      const { data } = await axios({
        url: `${process.env.VUE_APP_BASE_API}loginDP`,
        method: 'post',
        data: { username, password, code: '4', uuid: '1111' }
      })
      this.SET_TOKEN({
        token: data.token,
        tokenValidTime: data.tokenValidTime
      })

      // await sleep(1500)
      // const rank = Math.random()
      // console.log(rank)

      // if (rank > 0.5) {
      //   this.SET_TOKEN({
      //     token: 'sdfsdfsdfsdfsdfsd',
      //     tokenValidTime: '600'
      //   })
      // } else {
      //   return Promise.reject(new Error('刷新 token 失败！'))
      // }
    } catch {
      this.RESET_TOKEN()
      throw new Error('刷新 token 失败！')
    }
  }

  @Action
  public async LogOut() {
    try {
      this.RESET_TOKEN()
    } catch {
      throw new Error('退出登录接口调用失败！')
    }
  }
}

export const UserModule = getModule(User)
