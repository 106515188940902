/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M70.7 4.3l14 29.7c1 2.3 3.1 3.9 5.6 4.3l31.3 4.7c6.1 1 8.5 8.8 4.1 13.3l-22.7 23a8 8 0 0 0-2 7l5.3 32.6c1 6.3-5.4 11.2-10.8 8.2l-28-15.4a7.1 7.1 0 0 0-7 0l-28 15.4c-5.4 3-11.8-1.9-10.8-8.2l5.4-32.6a8 8 0 0 0-2.2-7l-22.6-23C-2.1 51.8.3 44 6.3 43l31.4-4.7c2.4-.4 4.5-2 5.6-4.3l14-29.7a7.3 7.3 0 0 1 13.4 0z"/>'
  }
})
