




import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { AppMain } from './components'

@Component({
  name: 'Layout',
  components: {
    AppMain
  }
})
export default class extends mixins() {
}
