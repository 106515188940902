import Vue from 'vue'
import Vuex from 'vuex'
import { IUserState } from './modules/user'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

export interface IRootState {
  user: IUserState
}

const vuexLocal = new VuexPersistence<IRootState>({
  storage: window.localStorage
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin]
})
