/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fullscreen': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M38.5 52L52 38.5 28.4 14.8 43.2 0H0v43.1l14.8-14.8L38.5 52zm74.7 47.7L89.5 76 76 89.5l23.6 23.7L84.8 128H128V84.9l-14.8 14.8zM89.5 52l23.7-23.6L128 43.2V0H84.9l14.8 14.8L76 38.5 89.5 52zm-51 24L14.8 99.7 0 84.7V128h43.1l-14.8-14.8L52 89.5 38.5 76z"/>'
  }
})
